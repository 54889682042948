<script>
  let loading = "assets/images/loading.json";
</script>

<section class="">
  <div class="flex justify-center items-center w-full min-h-screen">
    <lottie-player
      src={loading}
      background="transparent"
      speed="1.25"
      class="w-96"
      loop
      autoplay
    />
  </div>
</section>
