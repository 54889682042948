<script>
  import SimpleHeader from "./simpleHeader.svelte";
  import FooterBanner from "./footerBanner.svelte";
  export let values;
  export let profileimg;
  export let name;
  let finalData;

  let avatar = "/assets/images/avatar.svg";
  $: {
    if (values) {
      let a = JSON.stringify(values);
      finalData = a.substring(1, a.length - 1);
      finalData = finalData.replace(/,/g, " , ");
      // console.log(finalData);
    }
  }
</script>

<main class="">
  <SimpleHeader />
  <div
    class="md:min-h-full min-h-[70vh] text-center flex flex-col justify-center items-center font-Roboto space-y-8 xl:px-[30vw] lg:px-40 md:px-30 px-12 py-8 md:min-h-full min-h-[75vh]"
  >
    <img
      src={profileimg ? profileimg : avatar}
      alt="avatar"
      class="w-24 rounded-full"
    />
    <h1 class="font-bold md:text-3xl text-xl">
      You've marked {name ? name : "NA"} safe
    </h1>
    <p class="text-center">
      You've selected
      <span class="text-responsive"> {finalData} </span>
      to mark {name} safe.
    </p>
  </div>
  <FooterBanner />
</main>
