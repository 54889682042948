<script>
  import {
    dateTimeConverion,
    bloodgroupValue,
    crashType,
    roundoff,
  } from "../resources/handyfuncs";
  let avatar = "/assets/images/avatar.svg";
  let helmetimg = "/assets/images/image-not-found.svg";
  let card = "/assets/images/card.svg";
  let callicon = "/assets/images/call_icon.svg";
  let helmet = "assets/images/Fly-helmet.png";
  export let profileProps;

  // $: console.log(profileProps);
</script>

<main>
  <div
    class="flex lg:flex-col flex-col-reverse font-Roboto text-base text-white lg:pl-6 px-4 lg:pt-0 lg:min-w-[350px] pt-4 relative space-y-4"
  >
    <div class="relative md:mt-0 mt-4">
      <div>
        <img src={card} alt="card" />
      </div>
      <div class="absolute inset-x-6 inset-y-4 text-base">
        <div class="space-y-4">
          <!-- Heading -->
          <h1
            class={`font-Roboto  font-bold text-2xl pt-3  ${
              crashType(profileProps?.type) === "SOS"
                ? "text-[#e88600]"
                : "text-[#ee4040]"
            }`}
          >
            {crashType(profileProps?.type) === "SOS"
              ? "SOS Beacon (Live)"
              : "Crash Alert"}
          </h1>
          <!-- profile image box -->
          <div class="flex justify-between items-center">
            <div class="flex space-x-4 items-center">
              <img
                src={profileProps?.avatar ? profileProps?.avatar : avatar}
                alt="avatar"
                class="w-12"
              />
              <div class="font-semibold">
                <p class="text-base">{profileProps?.name}</p>
                <p class="text-xs">
                  {bloodgroupValue(profileProps?.blood_group)} Blood Group
                </p>
              </div>
            </div>
            <div>
              <button class="btn-primary font-Roboto">
                <a
                  href={`tel:${profileProps?.country_code}${profileProps?.mobile_number}`}
                >
                  <p class="xs:block hidden">Call Now</p>
                  <img src={callicon} alt="call" class="xs:hidden block w-4" />
                </a>
              </button>
            </div>
          </div>
          <!-- Information -->
          <div>
            <p class="text-base leading-5 py-2">
              <span class="font-Roboto font-bold">{profileProps?.name}</span>
              {crashType(profileProps?.type) === "SOS"
                ? "has triggered an "
                : "had a "}
              <span
                class={`font-Roboto font-bold ${
                  crashType(profileProps?.type) === "SOS"
                    ? "text-[#e88600]"
                    : "text-[#ee4040]"
                }`}
              >
                {crashType(profileProps?.type) === "SOS"
                  ? "SOS Beacon"
                  : "Crash "}</span
              > and might requires your assistance. Tap on call now to connect with
              your friend.
            </p>
          </div>
          <!-- Details block -->
          <div class="space-y-2">
            <!-- Phone Details -->
            <div class="space-y-2">
              <div class="flex items-center justify-between">
                <p class="text-[#a8a8a8] font-semibold">Phone Details</p>
                <div class="h-[1.5px] w-[60%] bg-[#a8a8a8]" />
              </div>
              <div class="flex justify-between items-center">
                <div>
                  <p class="font-bold capitalize">
                    {profileProps?.mobile?.name}
                  </p>
                  <p class="text-xs text-[#a8a8a8]">Phone Model</p>
                </div>
                <div>
                  <p class="font-bold text-[#4ef8e8] text-right">
                    {roundoff(profileProps?.mobile?.current_battery, 0)}%
                  </p>
                  <p class="text-xs text-[#a8a8a8]">Phone Battery</p>
                </div>
              </div>
            </div>
            <!-- Helmet Details -->
            <div class="space-y-2">
              <div class="flex items-center justify-between">
                <p class="text-[#a8a8a8] font-semibold">Helmet Details</p>
                <div class="h-[1.5px] w-[60%] bg-[#a8a8a8]" />
              </div>
              <div class="flex justify-between items-center">
                <div class="flex space-x-4 items-center">
                  <div>
                    <!-- Realtime image -->
                    <!-- <img
                      src={profileProps?.helmet?.url
                        ? profileProps?.helmet?.url
                        : helmetimg}
                      alt="helmetimg"
                      class="w-8"
                    /> -->

                    <!-- Image placeholder -->
                    <img src={helmet} alt="helmet" class="w-8" />
                  </div>
                  <div>
                    <p class="font-bold">
                      {typeof profileProps?.helmet?.name === "undefined"
                        ? "NA"
                        : profileProps?.helmet?.name}
                    </p>
                    <p class="text-xs text-[#a8a8a8]">Helmet Model</p>
                  </div>
                </div>
                <div>
                  <p class="font-bold text-[#4ef8e8] text-right">
                    {roundoff(profileProps?.helmet?.current_battery, 0)}%
                  </p>
                  <p class="text-xs text-[#a8a8a8]">Helmet Battery</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-[#313131] p-4 rounded-md text-base space-y-4 ">
      <div class="flex justify-between items-center font-bold">
        <p>
          {crashType(profileProps?.type)} Triggered on :<br />
          {dateTimeConverion(
            profileProps?.locations[profileProps?.impact_index]?.timestamp
          )}
        </p>
        <div>
          <button class="btn-primary">
            <a
              href={`https://www.google.com/maps/search/${
                profileProps?.locations[profileProps?.impact_index]?.lat
              },${profileProps?.locations[profileProps?.impact_index]?.lng}`}
              target="_blank"
              rel="noopener noreferrer">Navigate</a
            >
          </button>
        </div>
      </div>
      <p
        class={`${
          crashType(profileProps?.type) === "SOS" ? "block" : "hidden"
        }  text-xs`}
      >
        Pressing the navigate button will direct you to the last updated
        location of the rider.
      </p>
    </div>
  </div>
</main>
