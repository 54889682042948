<script>
  let playlogo = "/assets/images/Google_Play_Store.svg";
  let appstorelogo = "/assets/images/App_Store.png";
</script>

<div class="relative overflow-hidden">
  <div
    class="w-full bg-[url('/assets/images/footer-web.webp')] bg-center bg-contain bg-no-repeat font-ABUSFagoPro text-white overflow-hidden md:pb-[44.17%] pb-[46%] before:absolute z-0"
  />
  <div
    class="absolute z-2 xl:bottom-10 lg:bottom-6 md:bottom-4 sm:bottom-3 xs:bottom-2.5 bottom-2 lg:right-16 md:right-10 sm:right-8 xs:right-6 right-3"
  >
    <h1
      class="2xl:text-xl lg:text-lg md:text-base xs:text-sm mxs:text-xs text-2xs font-medium font-Roboto text-white md:pb-2 pb-1"
    >
      Available on
    </h1>
    <div class="flex items-center md:space-x-6 space-x-3">
      <a
        href="/download"
        target="_blank"
        ><img
          class="lg:w-[140px] sm:w-[100px] xs:w-[70px] w-[60px]"
          alt="Get it on Google Play"
          src={playlogo}
        /></a
      >
      <a
        href="/download"
        target="_blank"
        ><img
          class="lg:w-[140px] sm:w-[100px] xs:w-[70px] w-[60px]"
          alt="Get it on Google Play"
          src={appstorelogo}
        />
      </a>
    </div>
  </div>
</div>
