<script>
  // your script goes here
  import SimpleHeader from "./simpleHeader.svelte";
  import FooterBanner from "./footerBanner.svelte";
</script>

<div class="bg-[#141414]">
  <SimpleHeader />
  <section
    class="flex items-center h-full p-16 text-white font-Roboto md:min-h-full min-h-[70vh]"
  >
    <div
      class="container flex flex-col items-center justify-center px-5 mx-auto my-8"
    >
      <div class="max-w-md text-center">
        <h2 class="mb-8 font-extrabold text-9xl">
          <span
            class="absolute w-[1px] h-[1px] p-0 -m-[1px] border-0 whitespace-nowrap overflow-hidden"
            >Error</span
          >404
        </h2>
        <p class="text-2xl font-semibold md:text-3xl">
          Sorry, we couldn't find this page.
        </p>
      </div>
    </div>
  </section>
  <FooterBanner />
</div>
