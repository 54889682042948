<script>
  import SimpleHeader from "../components/simpleHeader.svelte";
  import FooterBanner from "../components/footerBanner.svelte";
  import Loading from "../components/loading.svelte";
  import AllowScreen from "../components/allowScreen.svelte";

  import { useQuery } from "@sveltestack/svelte-query";
  import api from "../resources/api";
  import { query } from "../resources/queryparams";

  let avatar = "/assets/images/avatar.svg";

  const apidata = useQuery("people", async () => {
    try {
      const res = await api.get(`/user/buddy-details?hash=${query.hash}`);
      return res;
    } catch (error) {
      // console.log(error?.response?.data);
      return error?.response?.data;
    }
  });

  let value;
  $: {
    value = $apidata;
    console.log(value?.data);
    // console.log(value?.data?.data?.data);
  }

  function allowButton() {
    api
      .patch(`/user/accept-buddy?hash=${query.hash}`, { hash: query.hash })
      .then((res) => {
        document.getElementById("main").classList.add("hidden");
        document.getElementById("allow").classList.remove("block");
      })
      .catch((err) => {
        // console.log(err);
        if (
          err.response.data.status === false &&
          err.response.data.message === "This link has already been used."
        ) {
          document.getElementById("main").classList.add("hidden");
          document.getElementById("allow").classList.remove("hidden");
        }
      });
  }
</script>

{#if value.status === "loading" && value.data === undefined}
  <div class="w-full h-screen">
    <Loading />
  </div>
{:else if value?.data?.status === false && value?.data?.message === "This link has already been used."}
  <section class="min-h-auto overflow-hidden bg-[#141414] text-white">
    <SimpleHeader />
    <div>
      <div>
        <AllowScreen
          img={value?.data?.data?.data?.user?.avatar
            ? value?.data?.data?.data?.user?.avatar
            : avatar}
          name={value?.data?.data?.data?.user?.name}
        />
      </div>
    </div>
    <FooterBanner />
  </section>
{:else}
  <section class="min-h-auto overflow-hidden bg-[#141414] text-white">
    <SimpleHeader />
    <div>
      <div id="main text-white">
        <div
          class=" md:min-h-full min-h-[72vh] flex flex-col items-center justify-center lg:pt-10 lg:pb-12 md:py-[72px] space-y-6 font-Roboto xs:py-[85px] py-[105px]"
        >
          <div class="flex justify-center items-center flex-col space-y-3">
            <img
              class="w-[90px] rounded-full"
              src={value?.data?.data?.data?.user?.avatar
                ? value?.data?.data?.data?.user?.avatar
                : avatar}
              alt="avatar"
            />
            <h1
              class="text-lg text-center xl:px-[0vw] md:px-[90px] px-[45px] font-bold leading-6"
              id="eContact"
            >
              {value?.data?.data?.data?.user?.name
                ? value?.data?.data?.data?.user?.name
                : "Quin Designs"}
              wants to add you as an Emergency Contact
            </h1>
          </div>
          <p
            class="text-sm text-center font-Roboto xl:px-[25vw] md:px-[15vw] px-[25px]"
            id="allowText"
          >
            By clicking “Allow” you will be added as an Emergency Contact for
            {value?.data?.data?.data?.user?.name
              ? value?.data?.data?.data?.user?.name
              : "Quin Designs"}. You will receive their location details in the
            event of an emergency.
          </p>
          <button
            on:click={allowButton}
            id="allowBtn"
            class="text-sm btn-primary px-8 py-1 rounded-full font-semibold"
            >ALLOW</button
          >
        </div>
      </div>
      <div id="allow" class="hidden">
        <AllowScreen
          img={value?.data?.data?.data?.user?.avatar
            ? value?.data?.data?.data?.user?.avatar
            : avatar}
          name={value?.data?.data?.data?.user?.name}
        />
      </div>
    </div>
    <FooterBanner />
  </section>
{/if}
