// import SosResponderMap from "../pages/SosRespondermap.svelte";
// import CrashResponderMap from "../pages/CrashRespondermap.svelte";
// import AllowScreen from "../components/allowScreen.svelte";
// import Copy from "../pages/copy.svelte";
// import DownloadApp from "../components/DownloadApp.svelte";

// IMPORTS
import OnBoardingResponder from "../pages/onboardingResponder.svelte";
import Crash from "../pages/crash.svelte";
import Sos from "../pages/sos.svelte";
import ErrorPage from "../components/ErrorPage.svelte";
import Loading from "../components/Loading.svelte";

export default [
  {
    path: "/",
    component: OnBoardingResponder,
  },
  {
    path: "/sos",
    component: Sos,
  },
  {
    path: "/crash",
    component: Crash,
  },
  // {
  //   path: "/allow",
  //   component: AllowScreen,
  // },
  // {
  //   path: "/copy",
  //   component: Copy,
  // },
  {
    path: "/load",
    component: Loading,
  },
  {
    path: "*",
    component: ErrorPage,
  },
];
