<script>
  let onelink = "/assets/images/onelink.svg";
</script>

<div>
  <div
    class="md:min-h-full min-h-[72vh] flex flex-col justify-center items-center w-full space-y-4 font-Roboto py-12 md:px-0 px-4 text-white"
  >
    <h1
      class="md:text-3xl sm:text-2xl xs:text-xl 2xs:text-base text-sm font-bold"
    >
      Download the FLY Smart App for Free
    </h1>
    <p class="font-bold sm:text-sm xs:text-xs 2xs:text-2xs text-3xs">
      Scan the QR code to download
    </p>
    <img src={onelink} alt="onelink" class="p-4 w-36" />
    <div>
      <ul
        class="space-y-4 sm:text-sm xs:text-xs 2xs:text-2xs text-3xs"
      >
        <li class="list-disc">Open the camera app on your phone.</li>
        <li class="list-disc">Focus the camera on the QR code.</li>
        <li class="list-disc">
          Tap the notification that pops up to open the download link.
        </li>
      </ul>
    </div>
  </div>
</div>
