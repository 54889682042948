<script>
  import { useQuery } from "@sveltestack/svelte-query";
  import axios from "axios";

  import Maps from "../components/maps.svelte";
  import Disclaimer from "../components/disclaimer.svelte";
  import FooterBanner from "../components/footerBanner.svelte";
  import Profile from "../components/profile.svelte";
  import MobileProfile from "../components/profileMobile.svelte";
  import Loading from "../components/loading.svelte";
  import MarksafeSuccess from "../components/marksafeSuccess.svelte";

  import { sos_store } from "../store/index";
  import api from "../resources/api";
  import { query } from "../resources/queryparams";

  let sosLogo = "/assets/images/sos-logo.webp";
  let sosMarker = "/assets/images/Orange-Beacon.gif";
  let avatar = "/assets/images/avatar.svg";
  let cross = "/assets/images/Cross_Icon.svg";
  let option1, option2, option3, option4, option5, popupStatus, crossStatus;
  option1 =
    option2 =
    option3 =
    option4 =
    option5 =
    popupStatus =
    crossStatus =
      false;
  let sosData,
    label = [];
  let sosProp = {};

  const sos = useQuery("sos", async () => {
    const response = await api.get(`/events?event_id=${query.event_id}`);
    return response;
  });

  $: {
    // console.log($sos?.data?.data?.data);
    sosData = $sos;
    $sos_store = sosData;
    sosProp = {
      avatar: sosData?.data?.data?.data?.avatar
        ? sosData?.data?.data?.data?.avatar
        : avatar,
      blood_group: sosData?.data?.data?.data?.blood_group,
      country_code: sosData?.data?.data?.data?.country_code,
      helmet: sosData?.data?.data?.data?.helmet,
      impact_index: sosData?.data?.data?.data?.impact_index
        ? sosData?.data?.data?.data?.impact_index
        : 0,
      inserted_at: sosData?.data?.data?.data?.inserted_at,
      locations: sosData?.data?.data?.data?.locations,
      mobile: sosData?.data?.data?.data?.mobile,
      name: sosData?.data?.data?.data?.name,
      type: sosData?.data?.data?.data?.type,
      mobile_number:sosData?.data?.data?.data?.mobile_number,
    };
    // console.log(sosProp.locations);
  }

  // Mark safe functions

  function emergencyServices() {
    option1 = !option1;
    if (option1) {
      label = [
        ...label,
        document.getElementById("emergency-services").innerText,
      ];
    } else {
      var idx = label.indexOf(
        document.getElementById("emergency-services").innerText
      );
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function withRider() {
    option2 = !option2;
    if (option2) {
      label = [...label, document.getElementById("with-rider").innerText];
    } else {
      var idx = label.indexOf(document.getElementById("with-rider").innerText);
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function somebodyNearby() {
    option3 = !option3;
    if (option3) {
      label = [...label, document.getElementById("somebody-nearby").innerText];
    } else {
      var idx = label.indexOf(
        document.getElementById("somebody-nearby").innerText
      );
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function talkedRider() {
    option4 = !option4;
    if (option4) {
      label = [...label, document.getElementById("talked-rider").innerText];
    } else {
      var idx = label.indexOf(
        document.getElementById("talked-rider").innerText
      );
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function calledES() {
    option5 = !option5;
    if (option5) {
      label = [...label, document.getElementById("called-es").innerText];
    } else {
      var idx = label.indexOf(document.getElementById("called-es").innerText);
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function marksafe() {
    axios
      .patch("https://fly.quin.design/api/events/responder-mark-safe", {
        event_id: query?.event_id,
      })
      .then((res) => {
        // alert("event closed");
        document.getElementById("active").classList.replace("block", "hidden");
        document.getElementById("popup").classList.replace("hidden", "block");
        // console.log(res);
      })
      .catch((err) => {
        alert(
          `Error Code: ${err?.response?.status} \nError Message: ${err?.response?.data?.message}`
        );
      });
  }
</script>

{#if $sos.status === "loading"}
  <Loading />
{:else}
  <section id="active" class="block bg-[#141414]">
    <div>
      <div>
        <div>
          <nav
            class="bg-[#313131bd] flex justify-between items-center md:px-10 px-4 "
          >
            <div
              class="flex md:space-x-3 md:py-6 py-5 space-x-1.5 items-center"
            >
              <img
                src={sosLogo}
                alt="quin-logo"
                class="md:w-[220px] w-[160px]"
              />
            </div>
            <div>
              <button
                on:click={() => {
                  popupStatus = !popupStatus;
                }}
                class="py-1 md:px-7 px-4 sm:text-sm lg:text-base text-xs rounded-full btn-primary font-Roboto font-bold uppercase"
                >Mark safe</button
              >
            </div>
          </nav>
        </div>
      </div>
      <div>
        <div>
          <div
            class="flex lg:p-4 lg:pb-4 lg:flex-row flex-col w-full 2xl:min-h-[65vh] xl:min-h-[70vh] overflow-hidden"
          >
            <div class="lg:min-w-[calc(100vw_-_500px)] md:min-h-auto">
              <Maps
                location={{
                  lat: sosProp?.locations[0].lat,
                  lng: sosProp?.locations[0].lng,
                }}
                marker={sosMarker}
              />
            </div>
            <div class="md:block hidden">
              <Profile profileProps={sosProp} />
            </div>
            <div class="md:hidden block">
              <MobileProfile profileProps={sosProp} />
            </div>
          </div>
          <div>
            <Disclaimer />
          </div>
          <div>
            <FooterBanner />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        id="modal"
        class={`bg-black z-20 backdrop-blur-md backdrop-opacity-100 backdrop-brightness-75 bg-opacity-50 absolute inset-0 justify-center items-center font-Roboto h-full ${
          popupStatus ? "flex" : "hidden"
        }`}
      >
        <div
          class="lg:max-w-[35vw] rounded-lg font-Roboto w-full lg:mx-0 mx-5 bg-[#313131]"
        >
          <div
            class="py-3 px-5 rounded-t-md flex justify-between text-white relative"
          >
            <img
              src={sosProp?.avatar}
              alt="avatar"
              class="absolute md:w-24 md:h-24 w-16 h-16 left-1/2 -translate-x-1/2 -top-0 bg-black rounded-full -translate-y-1/2 border-[3px] border-[#4ef8e8]"
            />
            <div>
              <button
                on:click={() => {
                  popupStatus = !popupStatus;
                }}
              >
                <img
                  src={cross}
                  alt="cross"
                  class="w-4 right-7 absolute bottom-0"
                />
              </button>
            </div>
          </div>
          <div
            class="md:pt-6 pt-2 text-center text-white font-Roboto space-y-2"
          >
            <h1 class="text-2xl font-semibold">SOS BEACON</h1>
            <p class="fot-semibold">{sosProp?.name}</p>
          </div>
          <div
            class="md:py-6 py-4 flex justify-center flex-wrap font-semibold md:text-base text-xs px-2 space-x-3"
          >
            <button
              id="with-rider"
              on:click={withRider}
              class={`my-2  rounded-full py-1.5 md:px-2 px-3 border-[#707070] duration-100 ${
                option2
                  ? "bg-[#4ef8e8] text-[#313131] border-0"
                  : "text-white border-2"
              }`}>I am with the rider</button
            >
            <button
              id="somebody-nearby"
              on:click={somebodyNearby}
              class={`my-2  rounded-full py-1.5 md:px-2 px-3 border-[#707070] duration-100 ${
                option3
                  ? "bg-[#4ef8e8] text-[#313131] border-0"
                  : "text-white  border-2"
              }`}>I informed somebody who is nearby</button
            >
            <button
              id="talked-rider"
              on:click={talkedRider}
              class={`my-2  rounded-full py-1.5 md:px-2 px-3 border-[#707070] duration-100 ${
                option4
                  ? "bg-[#4ef8e8] text-[#313131] border-0"
                  : "text-white border-2"
              }`}>I talked to the rider over the phone</button
            >
            <button
              id="called-es"
              on:click={calledES}
              class={`my-2  rounded-full py-1.5 md:px-2 px-3 border-[#707070] duration-100 ${
                option5
                  ? "bg-[#4ef8e8] text-[#313131] border-0"
                  : "text-white  border-2"
              }`}>I called Emergency Services</button
            >
            <button
              id="emergency-services"
              on:click={emergencyServices}
              class={`my-2  rounded-full py-1.5 md:px-2 px-3 border-[#707070] duration-100 ${
                option1
                  ? "bg-[#4ef8e8] text-[#313131] border-0"
                  : "text-white  border-2"
              }`}>I am with the Emergency Services personnel</button
            >
          </div>
          <div class="pb-4 bg-opacity-500 rounded-b-md flex justify-center">
            <button on:click={marksafe} class="btn-primary px-6"
              >MARK SAFE</button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="popup" class="hidden">
    <MarksafeSuccess
      values={label}
      name={sosProp?.name}
      profileimg={sosProp?.avatar}
    />
  </section>
{/if}

<!-- 
    Sockets for SOS
   import { io } from "socket.io-client";

   const socket = io.connect("http://65.0.90.255:4004", {
     transports: ["websocket"],
   });
   socket.on("connect", () => {
     console.log("socket connected!", socket);
     socket.on(query.event_id, (data) => {
       console.log("recieving");
       console.log(data);
     });
   });
 -->
