<script>
  import DownloadApp from "./DownloadApp.svelte";

  let quinSquare = "/assets/images/fly-logo-square.svg";
  let bell = "/assets/images/bell-icon.svg";
  let shield = "/assets/images/shield-icon.svg";
  let nav = "/assets/images/nav-icon.svg";
  let avatar = "/assets/images/avatar.svg";

  export let img;
  export let name;

  function getApp() {
    document.getElementById("getapp").classList.replace("flex", "hidden");
    document.getElementById("download").classList.replace("hidden", "block");
  }
</script>

<div>
  <main
    class="flex flex-col items-center font-Roboto space-y-4 py-8 md:px-0 px-12 text-white"
    id="getapp"
  >
    <div class="flex flex-col items-center space-y-2">
      <div>
        <img
          class="w-[90px] rounded-full"
          src={img ? img : avatar}
          alt="logo"
        />
      </div>
      <h1 class="font-Roboto font-bold text-center leading-5">
        You’ve been successfully added as an Emergency Contact
        {name !== undefined ? "for " + name : ""}
      </h1>
    </div>
    <div class="flex flex-col items-center space-y-6">
      <div class="flex flex-col items-center space-y-3">
        <img src={quinSquare} alt="Quinlogo" class="w-12 h-12" />
        <h1 class="font-Roboto font-semibold">Get the Fly Smart App</h1>
      </div>
      <div class="md:space-y-6 space-y-4 flex flex-col">
        <div class="flex space-x-4 font-Roboto items-center leading-4">
          <div class="w-auto h-auto p-3  bg-[#4ef8e8] flex justify-center items-center rounded-full">
            <img
              src={bell}
              alt="bell"
              class="w-auto"
            />
          </div>
          <h1 class="text-responsive">Real time alerts in the event of an emergency</h1>
        </div>
        <div class="flex space-x-4 font-Roboto items-center">
          <div class="w-auto h-auto p-3 bg-[#4ef8e8] flex justify-center items-center rounded-full">
            <img
              src={nav}
              alt="nav"
              class="w-auto"
            />
          </div>
          <h1 class="text-responsive">Easy navigation to the location of emergency</h1>
        </div>
        <div class="flex space-x-4 font-Roboto items-center">
          <div class="w-auto h-auto p-3 bg-[#4ef8e8] flex justify-center items-center rounded-full">
            <img
              src={shield}
              alt="shield"
              class="w-auto"
            />
          </div>
          <h1 class="text-responsive">Updates: Rider Status and Dispatch of Emergency Services</h1>
        </div>
      </div>
      <div>
        <button class="font-Roboto px-8 btn-primary font-bold" on:click={getApp}
          >GET APP</button
        >
      </div>
      <div />
    </div>
  </main>
  <div class="hidden" id="download">
    <DownloadApp />
  </div>
</div>
