<script>
  import {
    dateTimeConverion,
    bloodgroupValue,
    crashType,
    roundoff,
  } from "../resources/handyfuncs";
  let avatar = "/assets/images/avatar.svg";
  let helmetimg = "/assets/images/image-not-found.svg";
  let card = "/assets/images/card-bottom.svg";
  let callicon = "/assets/images/call_icon.svg";
  let helmet = "/assets/images/Fly-helmet.png";
  export let profileProps;

  // $: console.log(profileProps);
</script>

<main>
  <div
    class="flex flex-col justify-between items-center font-Roboto text-responsive text-white lg:min-w-[350px] pt-4 relative space-y-4"
  >
    <div class="relative lg:pl-6 px-5 lg:pt-0">
      <div class="bg-[#313131] text-responsive p-4 rounded-xl">
        <div class="md:space-y-4 space-y-2">
          <!-- Heading -->
          <h1
            class={`font-Roboto  font-bold text-2xl pt-1 ${
              crashType(profileProps?.type) === "SOS"
                ? "text-[#e88600]"
                : "text-[#ee4040]"
            }`}
          >
            {crashType(profileProps?.type) === "SOS"
              ? "SOS Beacon (Live)"
              : "Crash Alert"}
          </h1>
          <!-- profile image box -->
          <div class="flex justify-between items-center">
            <div class="flex space-x-4 items-center">
              <img
                src={profileProps?.avatar ? profileProps?.avatar : avatar}
                alt="avatar"
                class="w-12"
              />
              <div
                class="font-semibold flex flex-col justify-center items-center"
              >
                <p class="text-responsive">{profileProps?.name}</p>
                <p class="md:text-xs text-2xs text-[#4ef8e8]">
                  {bloodgroupValue(profileProps?.blood_group)} Blood Group
                </p>
              </div>
            </div>
            <div>
              <button class="btn-primary font-Roboto">
                <a
                  href={`tel:${profileProps?.country_code}${profileProps?.mobile_number}`}
                >
                  <p class="max-w-[40vw]">Call Now</p>
                  <!-- <img src={callicon} alt="call" class="xs:hidden block w-4" /> -->
                </a>
              </button>
            </div>
          </div>
          <!-- Information -->
          <div>
            <p class="text-responsive leading-5 py-2">
              <span class="font-Roboto font-bold">{profileProps?.name}</span>
              {crashType(profileProps?.type) === "SOS"
                ? "has triggered an "
                : "had a "}
              <span
                class={`font-Roboto font-bold ${
                  crashType(profileProps?.type) === "SOS"
                    ? "text-[#e88600]"
                    : "text-[#ee4040]"
                }`}
              >
                {crashType(profileProps?.type) === "SOS"
                  ? "SOS Beacon"
                  : "Crash "}</span
              > and might requires your assistance. Tap on call now to connect with
              your friend.
            </p>
          </div>
          <!-- Line -->
          <div class="w-full h-[1.5px] bg-[#a8a8a8] bg-opacity-20" />
          <!-- Time and Navigate block -->
          <div>
            <div class="flex justify-between items-center font-bold">
              <p>
                {crashType(profileProps?.type)} Triggered on :<br />
                {dateTimeConverion(
                  profileProps?.locations[profileProps?.impact_index]?.timestamp
                )}
              </p>
              <div>
                <button class="btn-primary">
                  <a
                    href={`https://www.google.com/maps/search/${
                      profileProps?.locations[profileProps?.impact_index]?.lat
                    },${
                      profileProps?.locations[profileProps?.impact_index]?.lng
                    }`}
                    target="_blank"
                    rel="noopener noreferrer">Navigate</a
                  >
                </button>
              </div>
            </div>
            <p
              class={`py-3 ${
                crashType(profileProps?.type) === "SOS" ? "block" : "hidden"
              }  text-responsive`}
            >
              Pressing the navigate button will direct you to the last updated
              location of the rider.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="relative w-full px-3">
      <div class="">
        <img src={card} alt="card" class="w-full" />
      </div>
      <div
        class="absolute inset-x-4 inset-y-0 rounded-md text-responsive flex flex-col mxs:pt-6 pt-4 "
      >
        <!-- Details block -->
        <div class="mxs:space-y-2 space-y-1 px-6">
          <!-- Phone Details -->
          <div class="mxs:space-y-2 space-y-1">
            <div class="flex items-center justify-between">
              <p class="text-[#a8a8a8] font-semibold">Phone Details</p>
              <div class="h-[1.5px] w-[60%] bg-[#a8a8a8] bg-opacity-20" />
            </div>
            <div class="flex justify-between items-center">
              <div>
                <p class="font-bold capitalize">
                  {profileProps?.mobile?.name}
                </p>
                <p class="text-xs text-[#a8a8a8]">Phone Model</p>
              </div>
              <div>
                <p class="font-bold text-[#4ef8e8] text-right">
                  {roundoff(profileProps?.mobile?.current_battery, 0)}%
                </p>
                <p class="text-xs text-[#a8a8a8]">Phone Battery</p>
              </div>
            </div>
          </div>
          <!-- Helmet Details -->
          <div class="mxs:space-y-2 space-y-1">
            <div class="flex items-center justify-between">
              <p class="text-[#a8a8a8] font-semibold">Helmet Details</p>
              <div class="h-[1.5px] w-[60%] bg-[#a8a8a8] bg-opacity-20" />
            </div>
            <div class="flex justify-between items-center">
              <div class="flex space-x-4 items-center">
                <div>
                  <!-- REALTIME HELMET IMAGE -->
                  <!-- <img
                    src={profileProps?.helmet?.url
                      ? profileProps?.helmet?.url
                      : helmetimg}
                    alt="helmetimg"
                    class="w-8"
                  /> -->

                  <!-- Image placeholder -->
                  <img src={helmet} alt="helmet" class="w-8" />
                </div>
                <div>
                  <p class="font-bold">
                    {typeof profileProps?.helmet?.name === "undefined"
                      ? "NA"
                      : profileProps?.helmet?.name}
                  </p>
                  <p class="text-xs text-[#a8a8a8]">Helmet Model</p>
                </div>
              </div>
              <div>
                <p class="font-bold text-[#4ef8e8] text-right">
                  {roundoff(profileProps?.helmet?.current_battery, 0)}%
                </p>
                <p class="text-xs text-[#a8a8a8]">Helmet Battery</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
