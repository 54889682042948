<script>
  import { loader } from "../resources/gmaploader.js";
  import { onMount } from "svelte";
  import { mapStyles } from "../resources/mapstyles";

  let gmap, map, data;
  const markerImg = "/assets/images/sos-marker.svg";
  const flylogo = "/assets/images/map-logo.png";
  export let location;
  export let marker;

  data = {
    location: location,
  };

  onMount(() => {
    let responderLocation = {
      lat: 0,
      lng: 0,
    };
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        responderLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(responderLocation);
      });
    } else {
      console.log("hello");
    }

    loader.load().then(() => {
      // console.log(data)
      let userLocation = {
        lat: parseFloat(data.location.lat),
        lng: parseFloat(data.location.lng),
      };
      // console.log(userLocation)
      const mapOptions = {
        center: userLocation,
        zoom: 12,
        streetViewControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        styles: mapStyles[0],
        // zoomControl: true,
        // zoomControlOptions: {
        //   position: google.maps.ControlPosition.LEFT_TOP,
        // },
        // fullscreenControl: true,
      };
      // Map Init
      map = new google.maps.Map(gmap, mapOptions);
      // Map controls
      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(container);
      // Map markers
      new google.maps.Marker({
        position: userLocation,
        map: map,
        icon: {
          anchor: new google.maps.Point(80, 80),
          url: marker ? marker : markerImg,
          scaledSize: new google.maps.Size(160, 160),
        },
        optimized: false,
      });
    });
    // map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(container)
  });
</script>

<div
  class="lg:mb-0 mb-1 bg-gray-400 lg:rounded-md lg:min-h-full min-h-[65vh]"
  bind:this={gmap}
/>
<div id="container" class="w-full flex justify-center items-center">
  <img src={flylogo} alt="marker" class="md:w-1/3 w-1/2" />
</div>
